import { createContext, useCallback, useRef } from "react";
import Task from "../../../common/Model/Task";
import { TaskData } from "../../../common/Model/TaskStatus";
import AnnotationCC from "../../../common/Model/Communication/AnnotationCommunicationController";

export const AnnotationTasksContext = createContext<{
    get: (filter: string, cnt: number, offset: number) => Promise<Task[]>;
    delete: (datasetId: number) => Promise<void>;
    add: (dataset: TaskData) => Promise<Task | undefined>;
}>({
    get: async () => [],
    add: async () => {
        return undefined;
    },
    delete: async () => {},
});

export default function AnnotationToolsProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const tasks = useRef<Task[]>([]);

    const getTasks = useCallback(
        async (filter: string, cnt: number, offset: number) => {
            if (filter === "") {
                if (tasks.current.length - (offset + cnt) >= 0)
                    return tasks.current.slice(offset, offset + cnt);
                cnt = offset + cnt - tasks.current.length;
            }

            let res = await AnnotationCC.getTasks(
                filter,
                cnt,
                offset
            );

            if (filter !== "") return res;

            res = res.filter(
                (t1) =>
                    tasks.current.find((t2) => t1.id === t2.id) === undefined
            );

            tasks.current.push(...res);
            return tasks.current.slice(offset, offset + cnt);
        },
        []
    );

    const addTask = useCallback(async (data: TaskData) => {
        data = {
            ...data,
            physicians: data.physicians.filter((p) => p.assign),
        };
        const task = await AnnotationCC.newAnnotationTask(data);
        tasks.current.push(task);
        tasks.current.sort((t1, t2) => t1.name().localeCompare(t2.name()));
        return task;
    }, []);

    const removeTask = useCallback(async (taskId: number) => {
        await AnnotationCC.deleteAnnotationTask(taskId);
        tasks.current = tasks.current.filter((at) => at.id !== taskId);
    }, []);

    return (
        <AnnotationTasksContext.Provider
            value={{
                get: getTasks,
                add: addTask,
                delete: removeTask,
            }}
        >
            {children}
        </AnnotationTasksContext.Provider>
    );
}
