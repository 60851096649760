import { VisitResult } from "typescript";
import config from "../../../config";
import NewVisitToSend from "../../../physician/Model/NewVisitToSend";
import { SideType } from "../Conflict";
import AbstractCommunicationController from "./AbstractCommunicationController";
import DeanonymizationCC from "./DeanonymizationCommunicationController";

class VisitCommunicationController extends AbstractCommunicationController {
  private endpoints = {
    GET_EXERCISES: "/exercise",
    GET_TRAUMA_EVENTS: "/traumaEvent",
    GET_DRUGS: "/drug",
    GET_DRUG_FREQUENCIES: "/drug/frequency",
    GET_TREATMENTS: "/treatment",
    GET_DISTENSION_REASONS: "/distensionReason",
    GET_SCANS: "/scan",
    EDIT_MEDIA: "/media/:id",
    GET_VISITS: "/visit",
    GET_VISITS_STATS: "/visit/stats",
    CREATE_IN_PERSON_VISIT: "/visit/inPerson",
    GET_LAST_JOINT_REPORT: "/visit/jointLastReport",
    GET_LAST_VISIT_BASELINE: "/visit/remote/baseline",
    GET_VISIT_MEDIAS: "/visit/:id/media",
    GET_MEDIA_DETAILS: "/media/:id",
    GET_VISIT_DETAILS: "/visit/:id",
    GET_PATIENT_VISITS: "/visit/inPerson",
    GET_MEDIA_ONLY_VISITS: "/visit/inPerson/mediaOnly",
    GET_TODAY_VISIT: "/visit/inPerson/mediaOnly/today",
    PATCH_VISIT: "/visit/:id",
    GET_DIAGNOSIS: "/visit/diagnosis/:id",
    PATCH_VISIT_PATIENT: "/visit/:id/patient",
  };

  protected getHeaders(): { [key: string]: string } {
    let session = "";
    const sessionObjsString = localStorage.getItem(config.LOCAL_STORAGE_SESSION_KEY);
    let sessionObjs = sessionObjsString ? JSON.parse(sessionObjsString) : [];
    if(sessionObjs.length > 0) {
      session = sessionObjs.find((obj: any) => obj.active).sid;
    }

    return {
      [DeanonymizationCC.SESSION_HEADER_NAME]: session ?? "",
    };
  }

  patchVisitPatient = (id: number, patient: string) => {
    return this.patch(this.formatEndpoint(this.endpoints.PATCH_VISIT_PATIENT, id), {
      patient,
    });
  }

  getVisitBaseline = (patient: string) => {
    return this.get(this.endpoints.GET_LAST_VISIT_BASELINE, {
      patient,
    });
  }

  getDiagnosis = (id: number) => {
    return this.get(this.formatEndpoint(this.endpoints.GET_DIAGNOSIS, id));
  }

  editMedia = (id: number, scan?: string, side?: string, suitability?: boolean) => {
    return this.patch(this.formatEndpoint(this.endpoints.EDIT_MEDIA, id), {
      scan,
      side,
      suitability,
    });
  };

  getScans = (
    joint: string,
  ): Promise<{ name: string; joint: string; priority: number }[]> => {
    return this.get(this.endpoints.GET_SCANS, { joint });
  };

  getDrugs = (): Promise<{ name: string; unit: string }[]> => {
    return this.get(this.endpoints.GET_DRUGS);
  };

  getDrugFrequencies = (): Promise<{ id: number; frequency: string }[]> => {
    return this.get(this.endpoints.GET_DRUG_FREQUENCIES);
  };

  getTreatments = (): Promise<
    { id: number; name: string; description: string }[]
  > => {
    return this.get(this.endpoints.GET_TREATMENTS);
  };

  getExercises = (): Promise<{ name: string }[]> => {
    return this.get(this.endpoints.GET_EXERCISES);
  };

  getTraumaEvents = (): Promise<{ name: string }[]> => {
    return this.get(this.endpoints.GET_TRAUMA_EVENTS);
  };

  createInPersonVisit = (visit: NewVisitToSend) => {
    return this.post(this.endpoints.CREATE_IN_PERSON_VISIT, visit);
  };

  getDistensionReasons = (): Promise<{ id: number; name: string }[]> => {
    return this.get(this.endpoints.GET_DISTENSION_REASONS);
  };

  getLastJointReport = (
    patient: string,
    joint: string,
    side: SideType,
    date: string | Date,
  ) => {
    return this.get(this.endpoints.GET_LAST_JOINT_REPORT, {
      patient,
      joint,
      side,
      date,
    });
  };

  getVisits = (
    patient?: string, 
    start_date?: string, 
    end_date?: string, 
    has_diagnosis?: boolean,
    remote?: boolean,
    types?: string[],
    cnt?: number, 
    offset?: number) => {
      return this.get(this.endpoints.GET_VISITS, { patient, start_date, end_date, has_diagnosis, remote, types, cnt, offset });
    }

  getVisitsStats = (
    patient?: string, 
    start_date?: string, 
    end_date?: string, 
    remote?: boolean,
    types?: string[]) => {
      return this.get(this.endpoints.GET_VISITS_STATS, { patient, start_date, end_date, remote, types });
    }
  
  patchVisit = (
    id: number,
    physician: number,
    report: Object,
    type?: string,
    followed_visit?: string,
    followed_visit_description?: string,
  ) => {
    return this.patch(this.formatEndpoint(this.endpoints.PATCH_VISIT, id), {
      physician,
      type,
      followed_visit,
      followed_visit_description,
      report,
    });
  }

  getVisitMedias = (
    id: number,
    exclude?: number[],
  ) => {
    return this.get(this.formatEndpoint(this.endpoints.GET_VISIT_MEDIAS, id), {
      exclude,
    });
  };

  getMediaDetails = (id: number) => {
    return this.get(this.formatEndpoint(this.endpoints.GET_MEDIA_DETAILS, id), {
      video_format: "mp4",
    });
  };

  getVisitDetails = (id: number) => {
    return this.get(this.formatEndpoint(this.endpoints.GET_VISIT_DETAILS, id));
  };

  getPatientVisits = (patient: string, cnt: number, offset: number = 0) => {
    return this.get(this.endpoints.GET_PATIENT_VISITS, {
      patient,
      cnt,
      offset,
    });
  };

  getAllToDoVisits = (
    date: string | Date | undefined,
    cnt: number,
    offset: number = 0,
  ) => {
    return this.get(this.endpoints.GET_MEDIA_ONLY_VISITS, {
      date,
      cnt,
      offset,
    });
  };

  getPatientToDoVisits = (
    patient: string,
    date: string | Date | undefined,
    cnt: number,
    offset: number = 0,
  ) => {
    return this.get(this.endpoints.GET_MEDIA_ONLY_VISITS, {
      patient,
      date,
      cnt,
      offset,
    });
  };

  getPatientLiveVisit = (patient: string) => {
    return this.get(this.endpoints.GET_TODAY_VISIT, {
      patient,
    });
  };
}

const instance = new VisitCommunicationController(config.MAIN_API_URL);

export default instance;
