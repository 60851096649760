import "./App.css";
import React, { useContext, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./common/View/Login";
import PhysicianHeader from "./physician/View/PhysicianHeader";
import AdminHeader from "./admin/View/AdminHeader";
import theme from "./common/Theme";
import { ThemeProvider } from "@mui/material";
import UserContext from "./common/Model/LoggedUserContext";

import { PatientProvider } from "./physician/Model/Contexts/PatientContext";
import { VisitProvider } from "./physician/Model/Contexts/VisitContext";
import { NewVisitProvider } from "./physician/Model/Contexts/NewVisitContext";
import { CurrentJointProvider } from "./physician/Model/Contexts/CurrentJointContext";
import { UserType } from "./common/Model/User";
import PhysiciansProvider from "./admin/Model/Contexts/UsersContext";
import DatasetsProvider from "./admin/Model/Contexts/DatasetsContext";
import AnnotationToolsProvider from "./admin/Model/Contexts/AnnotationToolsContext";
import AnnotationTasksProvider from "./admin/Model/Contexts/AnnotationTasksContext";
import FunctionsProvider from "./admin/Model/Contexts/FunctionsContext";
import { StepProvider } from "./physician/Model/Contexts/StepContext";
import VisitList from "./physician/View/VisitList";

const CreatePatient = React.lazy(
  () => import("./physician/View/CreatePatient"),
);
const SearchVisit = React.lazy(() => import("./physician/View/SearchVisit"));
const VisitAnamnesis = React.lazy(
  () => import("./physician/View/VisitCreation/VisitAnamnesis"),
);
const SeeVisit = React.lazy(() => import("./physician/View/SeeVisit/SeeVisit"));
const VisitBaselineInfo = React.lazy(
  () => import("./physician/View/SeeVisit/VisitBaselineInfo"),
);
const JointSelection = React.lazy(
  () => import("./physician/View/VisitCreation/JointSelection"),
);
const JointDiagnosisContainer = React.lazy(
  () => import("./physician/View/VisitCreation/JointDiagnosisContainer"),
);
const VisitDiagnosis = React.lazy(
  () => import("./physician/View/VisitCreation/VisitDiagnosis"),
);
const Drug = React.lazy(
  () => import("./physician/View/VisitCreation/Drug/Drug"),
);
const EndVisit = React.lazy(
  () => import("./physician/View/VisitCreation/EndVisit"),
);
const Annotations = React.lazy(
  () => import("./physician/View/Annotation/Annotations"),
);
const AnnotationConflictsList = React.lazy(
  () => import("./physician/View/Annotation/AnnotationConflictsList"),
);

const AdminHome = React.lazy(() => import("./admin/View/AdminHome"));
const AdminUsers = React.lazy(() => import("./admin/View/Users/AdminUsers"));
const AdminAddUser = React.lazy(
  () => import("./admin/View/Users/AdminAddUser"),
);
const AdminDatasets = React.lazy(
  () => import("./admin/View/Datasets/AdminDatasets"),
);
const AdminAddDataset = React.lazy(
  () => import("./admin/View/Datasets/AdminAddDataset"),
);
const AdminTasks = React.lazy(
  () => import("./admin/View/AnnotationTasks/AdminTasks"),
);
const AdminAddTask = React.lazy(
  () => import("./admin/View/AnnotationTasks/AdminAddTask"),
);
const AdminTools = React.lazy(
  () => import("./admin/View/AnnotationTools/AdminTools"),
);
const AdminAddTool = React.lazy(
  () => import("./admin/View/AnnotationTools/AdminAddTool"),
);
const AdminMediaStats = React.lazy(
  () => import("./admin/View/MediaStats/AdminMediaStats"),
);

function App() {
  const [user, setUser] = useState(null);

  return (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={[user, setUser]}>
        {user ? <UserRoutes /> : <Login />}
      </UserContext.Provider>
    </ThemeProvider>
  );
}

const UserRoutes = () => {
  const [user] = useContext(UserContext);
  const [isCancelButtonActive, setCancelButtonActive] = useState(false);
  const [isForwardButtonActive, setForwardButtonActive] = useState(false);

  const activateButton = (buttonNumber) => {
    if (buttonNumber === 1) {
      setCancelButtonActive(true);
      setForwardButtonActive(false);
    } else if (buttonNumber === 2) {
      setCancelButtonActive(false);
      setForwardButtonActive(true);
    }
  };

  if (user.type === UserType.PHYSICIAN) {
    return (
      <PatientProvider>
        <VisitProvider>
          <NewVisitProvider>
            <CurrentJointProvider>
              <StepProvider>
                <PhysicianHeader activateButton={activateButton} />
                <Routes>
                  <Route index element={<VisitList />} />
                  <Route path="/newVisit" element={<VisitAnamnesis />} />
                  <Route
                    path="/newVisit/jointSelection"
                    element={<JointSelection />}
                  />
                  <Route
                    path="/newVisit/jointSelection/joint"
                    element={
                      <JointDiagnosisContainer
                        isCancelButtonActive={isCancelButtonActive}
                        isForwardButtonActive={isForwardButtonActive}
                        setCancelButtonActive={setCancelButtonActive}
                        setForwardButtonActive={setForwardButtonActive}
                      />
                    }
                  />
                  <Route
                    path="/newVisit/diagnosis"
                    element={<VisitDiagnosis />}
                  />
                  <Route path="/newVisit/drug" element={<Drug />} />
                  <Route path="/newVisit/endVisit" element={<EndVisit />} />
                  <Route path="/seeVisit" element={<SeeVisit />} />
                  <Route
                    path="/visitBaselineInfo"
                    element={<VisitBaselineInfo />}
                  />
                  <Route path="/newPatient" element={<CreatePatient />} />
                  <Route
                    path="/searchVisit"
                    element={<SearchVisit id={user.id} />}
                  />
                  <Route path="/annotations" element={<Annotations />} />
                  <Route
                    path="/annotations/conflicts/:taskId"
                    element={<AnnotationConflictsList />}
                  />
                </Routes>
              </StepProvider>
            </CurrentJointProvider>
          </NewVisitProvider>
        </VisitProvider>
      </PatientProvider>
    );
  } else if (user.type === UserType.ADMIN) {
    return (
      <AdminHeader>
        <PhysiciansProvider>
          <DatasetsProvider>
            <AnnotationToolsProvider>
              <AnnotationTasksProvider>
                <FunctionsProvider>
                  <Routes>
                    <Route index element={<AdminHome />} />
                    <Route path="/users" element={<AdminUsers />} />
                    <Route path="/users/add" element={<AdminAddUser />} />
                    <Route path="/datasets" element={<AdminDatasets />} />
                    <Route path="/datasets/add" element={<AdminAddDataset />} />
                    <Route path="/tools" element={<AdminTools />} />
                    <Route path="/tools/add" element={<AdminAddTool />} />
                    <Route path="/tasks" element={<AdminTasks />} />
                    <Route path="/tasks/add" element={<AdminAddTask />} />
                    <Route path="/mediaStats" element={<AdminMediaStats />} />
                  </Routes>
                </FunctionsProvider>
              </AnnotationTasksProvider>
            </AnnotationToolsProvider>
          </DatasetsProvider>
        </PhysiciansProvider>
      </AdminHeader>
    );
  }
};

export default App;
