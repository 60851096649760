import { useNavigate } from "react-router-dom";
import search from "../imgs/icons/search.png";
import filtersIcon from "../imgs/icons/filters.png";
import diagnose_image from "../imgs/icons/diagnose_image.png";
import CircularProgress from "@mui/material/CircularProgress";
import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useRef,
} from "react";
import { PatientContext } from "../Model/Contexts/PatientContext";
import VisitCC from "../../common/Model/Communication/VisitCommunicationController";
import DeanonymizationCC from "../../common/Model/Communication/DeanonymizationCommunicationController";
import MainContainer from "../../common/View/MainContainer";
import { RefreshButton } from "../Components/RefreshButton";
import { SkeletonsList } from "../Components/SkeletonsList";
import FiltersModal from "../Components/Modals/FiltersModal";
import VisitLine from "../Components/VisitLine";
import { MenuItem, Divider, Typography, Popover } from "@mui/material";
import nameChecker from "../ViewModel/NameChecker";
import { NewVisitContext } from "../Model/Contexts/NewVisitContext";
import NewVisit from "../Model/NewVisit";
import { VisitContext } from "../Model/Contexts/VisitContext";
import ChangePatientModal from "../Components/Modals/ChangePatientModal";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

const VISITS_AT_TIME = 15;
const PATIENTS_SEARCHED_AT_TIME = 10;

export default function VisitList() {
  const [visitListToShow, setVisitListToShow] = useState([]);
  const [filters, setFilters] = useState(null);
  const [currentSearchedPid, setCurrentSearchedPid] = useState("");
  const [visitSelectImpossible, setVisitSelectImpossible] = useState(false);
  const [patientNamesList, setPatientNamesList] = useState([]);
  const [loadingFirstVisits, setLoadingFirstVisits] = useState(false);
  const [loadingOtherVisits, setLoadingOtherVisits] = useState(false);
  const [visitsToDiagnose, setVisitsToDiagnose] = useState(0);
  const [loadingVisitsStats, setLoadingVisitsStats] = useState(false);
  const [visitsStatsError, setVisitsStatsError] = useState(null);
  const [networkError, setNetworkError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [endReached, setEndReached] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [hasSearchedPatient, setHasSearchedPatient] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showChangePatientModal, setShowChangePatientModal] = useState(false);
  const open = Boolean(anchorEl);

  const throttledScroll = useRef(null);

  const navigate = useNavigate();

  const { setSelectedPatient } = useContext(PatientContext);
  const { setSelectedVisit } = useContext(VisitContext);
  const { newVisit, setNewVisit } = useContext(NewVisitContext);

  const handleMenu = (event) => {
      setAnchorEl(event.currentTarget);
      event.preventDefault();
  };

  const handleClose = () => {
      setAnchorEl(null);
  };

  const addPatientNameToList = (newNameObj) => {
      if(newNameObj.name !== "") {
          setPatientNamesList((prevState) => [...prevState, newNameObj]);
      }
  };

  const clearAll = useCallback(() => {
    setOffset(0);
    setVisitListToShow([]);
    setNetworkError(null);
    setEndReached(false);
  }, [setVisitListToShow, setNetworkError, setEndReached, setOffset]);

  const getVisits = useCallback(async (searchTerm = "", start_date, end_date, has_diagnosis = undefined, remote = undefined, types = undefined, offsetParam = 0) => {
    try {
      let visits = await VisitCC.getVisits(
        searchTerm,
        start_date,
        end_date,
        has_diagnosis,
        remote,
        types,
        VISITS_AT_TIME,
        offsetParam
      );
      
      setOffset(offsetParam);
      if (visits.length === 0 || visits.length < VISITS_AT_TIME)
        setEndReached(true);
      if (visits.length > 0) {
        //if(filters !== null && !filters.alreadyDiagnosed) visits = visits.filter((visit) => visit.type !== "baseline");
        setVisitListToShow((prevState) => [...prevState, ...visits]);
      }
    } catch (err) {
      setNetworkError(err || "Errore inatteso");
      console.log(err)
    } finally {
      setLoadingFirstVisits(false);
      setLoadingOtherVisits(false);
    }
  }, [setVisitListToShow, setNetworkError, setLoadingFirstVisits, setLoadingOtherVisits]);
  
   const getNumberOfVisitsToDiagnose = useCallback(async (searchTerm = "", start_date, end_date, remote = undefined, types = undefined) => {
    setLoadingVisitsStats(true);
    try {
      const stats = await VisitCC.getVisitsStats(
        searchTerm,
        start_date,
        end_date,
        remote,
        types,
      );
      setVisitsToDiagnose(stats.visits_without_diagnosis);
    } catch (err) {
      setVisitsStatsError(err || "Errore inatteso");
    } finally {
      setLoadingVisitsStats(false);
    }
  }, [setLoadingVisitsStats, setVisitsStatsError, setVisitsToDiagnose]);

  const setupFiltersAndGetVisits = useCallback((filters, patientPid, offsetParam, getStats = false) => {
    if(filters !== null) {
      const start_date = filters.afterDate ? filters.afterDate : "1900-01-01";
      const end_date = filters.beforeDate ? filters.beforeDate : "2100-01-01";
  
      let has_diagnosis = undefined;
      if(filters.toBeDiagnosed && !filters.alreadyDiagnosed) has_diagnosis = false;
      if(!filters.toBeDiagnosed && filters.alreadyDiagnosed) has_diagnosis = true;
  
      let remote = undefined;
      if(filters.inPerson && !filters.remote) remote = false;
      if(!filters.inPerson && filters.remote) remote = true;
      
      let types = [];
      if(!(filters.checkUp && filters.urgent && filters.followUp && filters.baseline)) {
        if(filters.checkUp) types.push("checkup");
        if(filters.urgent) types.push("urgent");
        if(filters.followUp) {
          types.push("first_followup");
          types.push("second_followup");
        }
        if(filters.baseline) types.push("baseline");
      }

      if(!getStats) {
        if(offsetParam === 0) clearAll();
        if(filters.specificDate) {
          getVisits(patientPid, filters.specificDate, filters.specificDate, has_diagnosis, remote, types, offsetParam);
        } else {
          getVisits(patientPid, start_date, end_date, has_diagnosis, remote, types, offsetParam);
        }
      }
      else {
        getNumberOfVisitsToDiagnose(patientPid, start_date, end_date, remote, types);
      }
    }
    else {
      if(!getStats) {
        if(offsetParam === 0) clearAll();
        getVisits(patientPid, undefined, undefined, undefined, undefined, undefined, offsetParam);
      }
      else {
        getNumberOfVisitsToDiagnose(patientPid, undefined, undefined, undefined, undefined);
      }
    }
  }, [getVisits, getNumberOfVisitsToDiagnose, clearAll]);

  const filtersActive = useCallback(() => {
    if(filters !== null) 
      if(!(filters.afterDate === null && filters.beforeDate === null && filters.specificDate === null && filters.toBeDiagnosed && filters.alreadyDiagnosed &&
        filters.inPerson && filters.remote && filters.checkUp && filters.urgent && filters.followUp && filters.baseline))
          return "solid red";
    return "none";
  }, [filters]);

  const getPatients = useCallback(async (searchTerm, offsetParam) => {
    try {
      const patients = await DeanonymizationCC.getPatients(
        searchTerm,
        PATIENTS_SEARCHED_AT_TIME,
        offsetParam,
      );
      const patientsList = patients.map((patient) => {
        return {
          name: patient.name + " " + patient.surname,
          pid: patient.pid,
        };
      });
      setPatientNamesList(patientsList);
    } catch (err) {
      setNetworkError(err || "Errore inatteso");
    }
  }, [setPatientNamesList, setNetworkError]);

  const handleSelect = (visit) => {
    if(visit.type === "baseline") {
      setSelectedVisit(visit);
      setTimeout(() => {
        navigate("/visitBaselineInfo");
      }, 200);
    }
    else if(visit.has_diagnosis) {
      setSelectedVisit(visit);
      setTimeout(() => {
        navigate("/seeVisit");
      }, 200);
    }
    else if(visit.new_patient) {
      let nv = new NewVisit();
      nv.setVisitId(visit.id);
      nv.setPatient(visit.patient);
      //nv.setNewPatient(visit.new_patient);
      nv.setPhysician(visit.physician);
      nv.setRemote(visit.remote);
      nv.setHasDiagnosis(visit.has_diagnosis);
      nv.setType(visit.type);
      nv.setVisitDate(new Date(visit.date));
      setNewVisit(nv);
      setShowChangePatientModal(true);
    }
    else {
      console.log(visit)
      let nv = new NewVisit();
      nv.setVisitId(visit.id);
      nv.setPatient(visit.patient);
      //nv.setNewPatient(visit.new_patient);
      nv.setPhysician(visit.physician);
      nv.setRemote(visit.remote);
      nv.setHasDiagnosis(visit.has_diagnosis);
      nv.setType(visit.type);
      nv.setVisitDate(new Date(visit.date));
      setNewVisit(nv);
      navigate("/newVisit", { replace: true });
    }
  };

  const handleScroll = useCallback((e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (!endReached && (scrollTop + clientHeight) / scrollHeight >= 0.95) {
      if (!throttledScroll.current) {
        throttledScroll.current = setTimeout(() => {
          setLoadingOtherVisits(true);
          setupFiltersAndGetVisits(filters, currentSearchedPid, offset + VISITS_AT_TIME);
          throttledScroll.current = null;
        }, 750);
      }
    }
  }, [endReached, filters, offset, currentSearchedPid, setupFiltersAndGetVisits]);

  useEffect(() => {
    filtersActive();
    setupFiltersAndGetVisits(filters, currentSearchedPid, 0, true);
    setupFiltersAndGetVisits(filters, currentSearchedPid, 0);
  }, [filters, filtersActive, setupFiltersAndGetVisits, currentSearchedPid]);

  useEffect(() => {
    if (searchInput.length === 0 && hasSearchedPatient) {
      setAnchorEl(null);
      setCurrentSearchedPid("");
      setHasSearchedPatient(false);
      setupFiltersAndGetVisits(filters, "", 0, true);
      setupFiltersAndGetVisits(filters, "", 0);
    }
    else if (searchInput.length > 0) {
      getPatients(searchInput, 0);
    }
  }, [searchInput, getPatients, setupFiltersAndGetVisits, filters, hasSearchedPatient]);

  
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <MainContainer>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            gap: "1vw",
          }}
        >
            <img src={search} width={30} height={30} alt="" />
            <input
                style={{ width: "65%", height: "55px", fontSize: 24, borderRadius: "10px" }}
                autoComplete="off"
                type="search"
                name="name"
                placeholder="Cerca per nome o cognome..."
                onChange={(e) => {
                    setOffset(0);
                    setSearchInput(e.target.value);
                    handleMenu(e);
                }}
                value={searchInput}
                aria-controls={open ? "patient-names" : undefined}
                aria-haspopup="true"
            />
            {searchInput.length > 0 && <Popover
                anchorEl={anchorEl}
                id="patient-names"
                open={open}
                onClose={handleClose}
                disableAutoFocus={true}
                disableEnforceFocus={true}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 5.5,
                        width: 560,
                    },
                }}
                transformOrigin={{ horizontal: "left", vertical: "top" }}
                anchorOrigin={{ horizontal: "left", vertical: "top" }}
            >
                {patientNamesList
                    .filter((nameObj, index, self) => self.findIndex(n => n.name.toLowerCase() === nameObj.name.toLowerCase()) === index)
                    .filter(nameObj => searchInput !== "" && nameObj.name.toLowerCase().includes(searchInput.toLowerCase()))
                    .map((nameObj, index) => (
                        <div>
                            <Divider style={{ opacity: 1, marginBottom: '5px' }} />
                            <MenuItem key={index} style={{ fontSize: 24 }} onClick={() => { 
                                setHasSearchedPatient(true);
                                clearAll();
                                handleClose();
                                setCurrentSearchedPid(nameObj.pid);
                                setupFiltersAndGetVisits(filters, nameObj.pid, 0, true);
                                setupFiltersAndGetVisits(filters, nameObj.pid, 0);
                            }}>
                                {nameChecker(nameObj.name)}
                            </MenuItem>
                        </div>
                    ))
                }
            </Popover>}

            <div
                style={{
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "space-between",
                    width: "50%",
                }}
            >
                <div>
                    <button
                        className="btn btn-primary"
                        style={{ fontSize: 24, border: filtersActive() }}
                        onClick={() => setShowFilters(true)}
                    >
                        Filtri{" "}
                        <img
                            src={filtersIcon}
                            width={40}
                            style={{ filter: `invert(100%)` }}
						    alt=""
                        />
                    </button>
                </div>
            </div>

            {visitsStatsError !== null ? (
              <Typography style={{ width: "100%", fontSize: 20, textAlign: "right" }}>{loadingVisitsStats && <CircularProgress/>} Impossibile ottenere numero di visite da diagnosticare</Typography>
            ) : (
              <Typography style={{ width: "40%", fontSize: 20, textAlign: "right" }}>{loadingVisitsStats ? <CircularProgress/> : visitsToDiagnose} da diagnosticare</Typography>
            )}
            <img src={diagnose_image} width={40} alt="" />
        </div>

        <div
          onScroll={handleScroll}
          style={{
            width: "100%",
            height: "72vh",
            overflow: "auto",
            textAlign:
              loadingFirstVisits || networkError !== null ? "center" : "left",
            borderRadius: "15px",
            border: "0.5px solid #56AEC9",
            boxShadow: "1px 2px 6px #56AEC9",
          }}
        >
          {loadingFirstVisits && <SkeletonsList />}
          {networkError !== null && (
            <div style={{ marginTop: "1%" }}>
              Errore nell'ottenere lista visite
              <RefreshButton
                onClick={() => {
                  getVisits();
                }}
              />
            </div>
          )}
          {!loadingFirstVisits &&
            networkError === null &&
            visitListToShow.length > 0 && (
              <table className="table table-primary table-striped table-hover">
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    height: "6vh",
                    textAlign: "center",
                  }}
                >
                  <tr>
                    <th style={{ background: "white" }}></th>
                    <th style={{ background: "white" }}>Data</th>
                    <th style={{ background: "white" }}>Medico</th>
                    <th style={{ background: "white" }}>Paziente</th>
                    <th style={{ background: "white" }}>Tipo visita</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                  {visitListToShow.filter(
                      (item, index, self) =>
                        index === self.findIndex((t) => t.id === item.id)
                    )
                    /*.filter((visit) => {
                      if (currentSearchedPid !== "") return visit.patient === currentSearchedPid;
                      else return visit;
                    })*/
                    .map((visit, index) => (
                      <VisitLine
                        key={index}
                        visit={visit}
                        isSelected={visit === newVisit}
                        visitSelectImpossible={visitSelectImpossible}
                        setVisitSelectImpossible={setVisitSelectImpossible}
                        onAddPatientNameToList={addPatientNameToList}
                        onSelectVisit={(patient) => {
                          setSelectedPatient(patient);
                          handleSelect(visit);
                        }}
                      />
                    ))}
                </tbody>
              </table>
            )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {(loadingFirstVisits || loadingOtherVisits) && <CircularProgress />}
          </div>
          {endReached && (
            <tfoot
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: 14,
              }}
            >
              <p>
                <em>Non sono presenti altre visite</em>
              </p>
            </tfoot>
          )}
        </div>
        {showFilters !== false && (
          <FiltersModal
            filtersProps={filters}
            setShowModal={setShowFilters}
            clear={clearAll}
            onSetFilters={setFilters}
          />
        )}
        <ChangePatientModal
          show={showChangePatientModal}
          setShow={setShowChangePatientModal}
          getVisits={() => {
            clearAll();
            setupFiltersAndGetVisits(filters, currentSearchedPid, 0);
          }}
        />
        <Snackbar
            open={visitSelectImpossible}
            autoHideDuration={2000}
            anchorOrigin={{
                open: !visitSelectImpossible,
                vertical: "top",
                horizontal: "center",
            }}
        >
            <Alert
                severity="error"
                variant="filled"
                sx={{ width: "100%" }}
            >
                Dati del paziente non disponibili: impossibile selezionare la visita.
            </Alert>
        </Snackbar>
      </MainContainer>
    </div>
  );
}
