import { Skeleton } from "@mui/material";

export const SkeletonsList = ({
    width = "100%",
    height = "4vh",
    marginBottom = 1,
    long = true,
}) => {
    const n = long ? 7 : 2;

    const skeletons = [];
    for (let i = 0; i < n; i++) {
        skeletons.push(
            <Skeleton
                key={i * 2}
                style={{ marginBottom }}
                variant="rectangular"
                width={width}
                height={height}
            />
        );
        skeletons.push(
            <Skeleton
                key={i * 2 + 1}
                animation={"wave"}
                style={{ marginBottom: 1 }}
                variant="rectangular"
                width={"100%"}
                height={"4vh"}
            />
        );
    }

    return <>{skeletons}</>;
};
