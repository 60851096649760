const config = {
	ENDPOINT: "cadet",
	MAIN_API_URL: "https://apollo.policlinico.mi.it/apiV3",
	DEANONYMIZATION_API_URL:
		"https://apollo.policlinico.mi.it/apiV3/deanonymization",
	LOCAL_STORAGE_SESSION_KEY: "ApolloSession",
	LOCAL_STORAGE_EXPIRE_KEY: "ApolloSessionExpire",
	NODE_ENV: "production",
};

export default config;
