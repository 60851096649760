import { useState, useContext, useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import config from "../../config";
import UserContext from "../Model/LoggedUserContext";
import Status from "../Model/Status";
import DeanonymizationCC from "../Model/Communication/DeanonymizationCommunicationController";

export default function AccountLine(props) {

    const [user, setUser] = useContext(UserContext);
    const [physician, setPhysician] = useState({});
    const [status, setStatus] = useState(Status.IDLE);
    const [networkError, setNetworkError] = useState(null);

    useEffect(() => {
        getPhysicianDetails();
    }, []);

    const getPhysicianDetails = async () => {
        try {
            const physician = await DeanonymizationCC.getPhysician(props.userIdObj.id);
            setPhysician(physician);
        } catch (err) {
            setNetworkError(err);
            setStatus(Status.ERROR);
        }
    }

    const loginWithOtherAccount = async () => {
        const sessionObjsString = localStorage.getItem(config.LOCAL_STORAGE_SESSION_KEY);
        let sessionObjs = sessionObjsString ? JSON.parse(sessionObjsString) : [];
        sessionObjs.forEach((obj) => {
            if(obj.sid === props.userIdObj.sid)
                obj.active = true;
            else
                obj.active = false;
        });
        localStorage.setItem(config.LOCAL_STORAGE_SESSION_KEY, JSON.stringify(sessionObjs));
        props.onLoginWithNewAccount(false);
    };

    return (
        <>
            <MenuItem onClick={() => loginWithOtherAccount() }>
                <Avatar/> {`${physician.name} ${physician.surname ? physician.surname : ''} (${physician.type})`}
            </MenuItem>
            <Divider style={{ opacity: 1, marginTop: '5px', marginBottom: '5px' }} />
        </>
    );
}
