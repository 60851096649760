import { useState, useContext, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import config from "../../config";
import UserContext from "../Model/LoggedUserContext";
import PasswordIcon from "@mui/icons-material/Password";
import EditPswModal from "../../physician/Components/Modals/EditPswModal";
import Status from "../Model/Status";
import AccountLine from "./AccountLine";

export default function AccountMenu(props) {
    const navigate = useNavigate();
    const [user, setUser] = useContext(UserContext);
    const [status, setStatus] = useState(Status.IDLE);
    const [networkError, setNetworkError] = useState(null);
    const [activeUsersIds, setActiveUsersIds] = useState([]);
    const [accordionExpanded, setAccordionExpanded] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [showEditPswModal, setShoEditPswModal] = useState(false);

    const location = useLocation();

    const open = Boolean(anchorEl);
    useEffect(() => {
        const sessionObjsString = localStorage.getItem(config.LOCAL_STORAGE_SESSION_KEY);
        let sessionObjs = sessionObjsString ? JSON.parse(sessionObjsString) : [];
        sessionObjs = sessionObjs.filter((obj) => !obj.active);
        setActiveUsersIds(sessionObjs);
    }, []);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleAccordionChange = () => {
        setAccordionExpanded(!accordionExpanded);
    };
    const loginWithNewAccount = useCallback(async (adding) => {
        if(adding) {
            const sessionObjsString = localStorage.getItem(config.LOCAL_STORAGE_SESSION_KEY);
            let sessionObjs = sessionObjsString ? JSON.parse(sessionObjsString) : [];
            sessionObjs.forEach((obj) => {
                obj.active = false;
            });
            localStorage.setItem(config.LOCAL_STORAGE_SESSION_KEY, JSON.stringify(sessionObjs));
        }
        setUser(null);
        navigate("/", { replace: true });
    }, [setUser, navigate]);

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                }}
            >
                {(location.pathname === "/" ||
                    location.pathname === "/annotations" ||
                    location.pathname.startsWith("/annotations/conflicts")) && 
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        sx={{ ml: 2 }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                    >
                        <Avatar
                            sx={{
                                width: 40,
                                height: 40,
                                background: "#4169e1",
                            }}
                        >
                            {user.getInitials()}
                        </Avatar>
                    </IconButton>
                </Tooltip>}
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        width: 384,
                        "& .MuiAvatar-root": {
                            width: 40,
                            height: 40,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem onClick={() => handleClose()} disabled>
                    <Avatar/> {`${user.name} ${user.surname ? user.surname : ''} (${user.type})`}
                </MenuItem>

                <Divider />

                <Accordion style={{ margin: '10px' }} expanded={accordionExpanded} onChange={handleAccordionChange}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>{accordionExpanded ? "Nascondi altri account" : "Mostra altri account"}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            {activeUsersIds
                                .filter((element) => !element.type.includes("admin"))
                                .map((element, index) => (
                                    <AccountLine key={index} userIdObj={element} onLoginWithNewAccount={loginWithNewAccount}/>
                                ))
                            }
                            <MenuItem onClick={() => loginWithNewAccount(true) }>Aggiungi un altro account</MenuItem>
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Divider />

                <MenuItem onClick={() => { setShoEditPswModal(true); handleClose() }}>
                    <ListItemIcon>
                        <PasswordIcon />
                    </ListItemIcon>
                    Cambia password
                </MenuItem>
                <Divider />
                <MenuItem onClick={props.onLogout}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
            <EditPswModal
                show={showEditPswModal}
                setShow={setShoEditPswModal}
            />
        </>
    );
}
