export default class Task {
  id: number;
  dataset: number;
  dataset_name: string;
  annotation_type: number;
  annotation_type_name: string;
  annotation_tool: number;
  medias: number;
  type: "image" | "video";
  annotations: number;
  physicians_cnt: number;
  assignment_date: Date;

  constructor(obj: Task) {
    this.id = obj.id;
    this.dataset = obj.dataset;
    this.dataset_name = obj.dataset_name;
    this.annotation_type = obj.annotation_type;
    this.annotation_type_name = obj.annotation_type_name;
    this.annotation_tool = obj.annotation_tool;
    this.medias = parseInt(obj.medias as any);
    this.type = obj.type;
    this.physicians_cnt = obj.physicians_cnt;
    this.annotations = obj.annotations;
    this.assignment_date = new Date(obj.assignment_date);
  }

  name = () => {
    return `${this.dataset_name} - ${this.annotation_type_name}`;
  };

  filter = (search: string): boolean => {
    search = search.toLowerCase();
    return this.name().toLowerCase().includes(search);
  };

  overallProgress = () => {
    let percent;
    if (this.medias > 0 && this.physicians_cnt > 0)
      percent = this.annotations / (this.medias * this.physicians_cnt);
    else percent = 0.0;
    return Math.round(percent * 10000) / 100;
  };
}
