import { createContext, useCallback, useRef } from "react";
import Dataset, { DatasetData } from "../Dataset";
import AnnotationCC from "../../../common/Model/Communication/AnnotationCommunicationController";

export const DatasetsContext = createContext<{
    get: () => Promise<Dataset[]>;
    delete: (datasetId: number) => Promise<void>;
    add: (dataset: DatasetData) => Promise<Dataset | undefined>;
}>({
    get: async () => [],
    add: async () => {
        return undefined;
    },
    delete: async () => {},
});

export default function DatasetsProvider({
    children,
}: {
    children: React.ReactNode;
}) {
    const synchronized = useRef<boolean>(false);
    const datasets = useRef<Dataset[]>([]);

    const getDatasets = useCallback(async () => {
        if (!synchronized.current) {
            let res = await AnnotationCC.getDatasets();
            synchronized.current = true;

            res = res.filter(
                (d1) =>
                    datasets.current.find((d2) => d1.id === d2.id) === undefined
            );

            datasets.current.push(...res);
            datasets.current.sort((d1, d2) => d1.name.localeCompare(d2.name));
        }
        return datasets.current;
    }, []);

    const addDataset = useCallback(async (data: DatasetData) => {
        const dataset = await AnnotationCC.newDataset(data);
        datasets.current.push(dataset);
        datasets.current.sort((d1, d2) => d1.name.localeCompare(d2.name));
        return dataset;
    }, []);

    const removeDataset = useCallback(async (datasetId: number) => {
        await AnnotationCC.deleteDataset(datasetId);
        datasets.current = datasets.current.filter((d) => d.id !== datasetId);
    }, []);

    return (
        <DatasetsContext.Provider
            value={{ get: getDatasets, add: addDataset, delete: removeDataset }}
        >
            {children}
        </DatasetsContext.Provider>
    );
}
