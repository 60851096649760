import { MediaType } from "../../admin/Model/Dataset";
import AnnotationCC from "./Communication/AnnotationCommunicationController";

export enum SideType {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export default class Conflict {
  media: number;
  type: MediaType;
  scan: string;
  joint: string;
  side: SideType;
  annotation: number;
  conflict_annotation: number;
  annotator: number;
  conflict_annotator: number;
  conflict_function: string;
  task: number;

  constructor(
    annotator: number,
    conflict_annotator: number,
    conflict_function: string,
    task: number,
    obj: Conflict,
  ) {
    this.media = obj.media;
    this.type = obj.type;
    this.scan = obj.scan;
    this.joint = obj.joint;
    this.side = obj.side;
    this.annotation = obj.annotation;
    this.conflict_annotation = obj.conflict_annotation;
    this.annotator = annotator;
    this.conflict_annotator = conflict_annotator;
    this.conflict_function = conflict_function;
    this.task = task;
  }

  getDetails = (): Promise<ConflictDetails> => {
    return AnnotationCC.getConflictDetails(
      this.task,
      this.annotator,
      this.conflict_annotator,
      this.media,
      this.conflict_function,
    );
  };

  acceptOther = (): Promise<void> => {
    return AnnotationCC.acceptConflict(
      this.annotation,
      this.conflict_annotation,
    );
  };
}

export type ConflictDetails = {
  media: number;
  type: MediaType;
  scan: string;
  joint: string;
  side: SideType;
  base64: string;
  annotation_str: string;
  conflict_annotation_str: string;
  edit_annotation_url: string | undefined;
};
