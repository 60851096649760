import dayjs from "dayjs";
import AnnotationCC from "./Communication/AnnotationCommunicationController";
import Task from "./Task";

export const isTaskDataValid = (data: TaskData) => {
    return (
        !isNaN(data.dataset) &&
        data.dataset >= 0 &&
        !isNaN(data.annotation_type) &&
        data.annotation_type >= 0 &&
        data.physicians.filter(
            (p) =>
                p.assign && (!p.deadline || dayjs(p.deadline).isBefore(dayjs()))
        ).length === 0
    );
};

export type AssignmentType = {
    user: number;
    assign: boolean;
    deadline: string | undefined;
};

export class TaskData {
    dataset: number = -1;
    annotation_type: number = -1;
    physicians: AssignmentType[] = [];
}

export type TaskDataKey = keyof TaskData;

export type TaskConflictsMatrix = {
    [key: number]: {
        [key: number]: {
            common_annotations: number;
            agreement_degree: number;
        };
    };
    fn: string;
};

export default class TaskStatus extends Task {
    physicians: {
        annotations: number;
        deadline: string;
        task_url: string;
        user: number;
    }[];

    print_function: string;
    conflict_functions: string[];

    constructor(obj: TaskStatus) {
        super(obj);
        this.physicians = obj.physicians;
        this.print_function = obj.print_function;
        this.conflict_functions = obj.conflict_functions;
    }

    getData = (): TaskData => {
        return {
            dataset: this.dataset,
            annotation_type: this.annotation_type,
            physicians: this.physicians.map((p) => ({
                user: p.user,
                deadline: p.deadline,
                assign: true,
            })),
        };
    };

    updateAssignment = async (
        assignments: AssignmentType[]
    ): Promise<boolean> => {
        assignments = this.filterAssignments(assignments);

        const status = await AnnotationCC.updateTaskAssignment(this.id, assignments);

        this.physicians = status;

        return true;
    };

    filterAssignments = (assignments: AssignmentType[]): AssignmentType[] => {
        return assignments.filter((a) => {
            const physician = this.physicians.find((p) => p.user === a.user);
            if (!physician) return a.assign;
            return !a.assign || physician.deadline !== a.deadline;
        });
    };

    conflictMatrix = (
        conflictFunction: string
    ): Promise<TaskConflictsMatrix> => {
        return AnnotationCC.getConflictMatrix(this.id, conflictFunction);
    };

    getConflicts = (
        user: number,
        other: number,
        conflictFunction: string,
        cnt: number,
        offset: number
    ) => {
        return AnnotationCC.getTaskConflicts(
            this.id,
            user,
            other,
            conflictFunction,
            cnt,
            offset
        );
    };
}
