import { Modal, Button } from "react-bootstrap";
import { Alert, AlertTitle, Divider } from "@mui/material";
import { useState, useEffect } from "react";
import "react-day-picker/dist/style.css";
import { format } from "date-fns"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/it";
import dayjs from 'dayjs';
import { Typography, Checkbox } from "@mui/material";

export default function FiltersModal(props) {
    const [disabled, setDisabled] = useState(false);
    const [sendingButton, setSendingButton] = useState("Conferma");
    const [showAlert, setShowAlert] = useState(null);
    const [networkError, setNetworkError] = useState(null);
    const [filters, setFilters] = useState({
        inPerson: props.filtersProps ? props.filtersProps.inPerson : true,
        remote: props.filtersProps ? props.filtersProps.remote : true,
        toBeDiagnosed: props.filtersProps ? props.filtersProps.toBeDiagnosed : true,
        alreadyDiagnosed: props.filtersProps ? props.filtersProps.alreadyDiagnosed : true,
        checkUp: props.filtersProps ? props.filtersProps.checkUp : true,
        urgent: props.filtersProps ? props.filtersProps.urgent : true,
        followUp: props.filtersProps ? props.filtersProps.followUp : true,
        baseline: props.filtersProps ? props.filtersProps.baseline : true,
        afterDate: props.filtersProps ? props.filtersProps.afterDate : null,
        beforeDate: props.filtersProps ? props.filtersProps.beforeDate : null,
        specificDate: props.filtersProps ? props.filtersProps.specificDate : null,
    });

    useEffect(() => {
        if(!filters.inPerson && !filters.remote) {
            setDisabled(true);
            setShowAlert("Selezionare almeno un tipo di visita");
        }
        else if(!filters.toBeDiagnosed && !filters.alreadyDiagnosed) {
            setDisabled(true);
            setShowAlert("Selezionare almeno un tipo di stato di diagnosi");
        }
        else if(!filters.checkUp && !filters.urgent && !filters.followUp && !filters.baseline) {
            setDisabled(true);
            setShowAlert("Selezionare almeno un tipo di visita");
        }
        else {
            setDisabled(false);
            setShowAlert(null);
        }
    }, [filters]);

    return (
        <Modal show={props.patient !== null} animation={true}>
            <Modal.Header style={{ backgroundColor: "#0288d1" }}>
                <Typography style={{ fontSize: 24, color: "white" }}>
                    Mostra visite:
                </Typography>
            </Modal.Header>
            <Modal.Body style={{ background: "whitesmoke" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "row", gap: "25vh", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            In presenza
                        </Typography>
                        <Checkbox checked={filters.inPerson} onClick={() => setFilters({...filters, inPerson: !filters.inPerson})}/>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "25vh", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            Remote
                        </Typography>
                        <Checkbox checked={filters.remote} onClick={() => setFilters({...filters, remote: !filters.remote})} />
                    </div>
                    <Divider style={{ opacity: 1, marginTop: '5px', marginBottom: '5px' }} />

                    <div style={{ display: "flex", flexDirection: "row", gap: "25vh", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            Da diagnosticare
                        </Typography>
                        <Checkbox checked={filters.toBeDiagnosed} onClick={() => setFilters({...filters, toBeDiagnosed: !filters.toBeDiagnosed})} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "25vh", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            Già diagnosticate
                        </Typography>
                        <Checkbox checked={filters.alreadyDiagnosed} onClick={() => setFilters({...filters, alreadyDiagnosed: !filters.alreadyDiagnosed})} />
                    </div>
                    <Divider style={{ opacity: 1, marginTop: '5px', marginBottom: '5px' }} />

                    <div style={{ display: "flex", flexDirection: "row", gap: "25vh", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            Check-up
                        </Typography>
                        <Checkbox checked={filters.checkUp} onClick={() => setFilters({...filters, checkUp: !filters.checkUp})} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "25vh", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            Urgente
                        </Typography>
                        <Checkbox checked={filters.urgent} onClick={() => setFilters({...filters, urgent: !filters.urgent})} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "25vh", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            Follow-up
                        </Typography>
                        <Checkbox checked={filters.followUp} onClick={() => setFilters({...filters, followUp: !filters.followUp})} />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "25vh", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            Baseline
                        </Typography>
                        <Checkbox checked={filters.baseline} onClick={() => setFilters({...filters, baseline: !filters.baseline})} />
                    </div>
                    <Divider style={{ opacity: 1, marginTop: '5px', marginBottom: '10px' }} />

                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "15px" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            Visite dal giorno:
                        </Typography>
                        <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="it"
                            >
                                <DatePicker
                                    sx={{ background: "white" }}
                                    slotProps={{ textField: { size: "small" } }}
                                    onAccept={(date) => setFilters({...filters, afterDate: date !== null ? format(date.$d, "y-MM-dd") : null, specificDate: null})}
                                    value={filters.afterDate ? dayjs(filters.afterDate) : null}
                                />
                        </LocalizationProvider>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginBottom: "15px" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            Visite fino al giorno:
                        </Typography>
                        <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="it"
                            >
                                <DatePicker
                                    sx={{ background: "white" }}
                                    slotProps={{ textField: { size: "small" } }}
                                    onAccept={(date) => setFilters({...filters, beforeDate: date !== null ? format(date.$d, "y-MM-dd") : null, specificDate: null})}
                                    value={filters.beforeDate ? dayjs(filters.beforeDate) : null}
                                />
                        </LocalizationProvider>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Typography variant="h6" style={{ color: "black", marginTop: "3px" }}>
                            Visite il giorno:
                        </Typography>
                        <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale="it"
                            >
                                <DatePicker
                                    sx={{ background: "white" }}
                                    slotProps={{ textField: { size: "small" } }}
                                    onAccept={(date) => setFilters({...filters, specificDate: date !== null ? format(date.$d, "y-MM-dd") : null, afterDate: null, beforeDate: null})}
                                    value={filters.specificDate ? dayjs(filters.specificDate) : null}
                                />
                        </LocalizationProvider>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer style={{ background: "whitesmoke" }}>
                {showAlert !== null && (
                    <Alert
                        style={{ width: "100%" }}
                        severity="warning"
                        variant="filled"
                    >
                        <AlertTitle>{showAlert}</AlertTitle>
                    </Alert>
                )}
                <Button
                    variant="secondary"
                    onClick={() => {
                        props.setShowModal(false);
                    }}
                    style={{ fontSize: 24 }}
                >
                    Annulla
                </Button>
                <Button
                    variant="warning"
                    onClick={() => {
                        setFilters({
                            inPerson: true,
                            remote: true,
                            toBeDiagnosed: true,
                            alreadyDiagnosed: true,
                            checkUp: true,
                            urgent: true,
                            followUp: true,
                            baseline: true,
                            afterDate: null,
                            beforeDate: null,
                            specificDate: null,
                        })
                    }}
                    style={{ fontSize: 24 }}
                >
                    Reset
                </Button>
                <Button
                    disabled={disabled}
                    variant="success"
                    onClick={() => {
                        props.onSetFilters(filters);
                        props.setShowModal(false);
                    }}
                    style={{ fontSize: 24 }}
                >
                    {sendingButton}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}