export default class PhysicianTask {
    id: number;
    dataset_name: string;
    annotation_type_name: string;
    medias: number;
    type: "image" | "video";

    physician: number;
    task_url: string;
    deadline: Date;
    annotations: number;

    constructor(obj: PhysicianTask) {
        this.physician = obj.physician;
        this.annotations = parseInt(obj.annotations as any);
        this.deadline = new Date(obj.deadline);
        this.task_url = obj.task_url;

        this.id = obj.id;
        this.dataset_name = obj.dataset_name;
        this.annotation_type_name = obj.annotation_type_name;
        this.medias = parseInt(obj.medias as any);
        this.type = obj.type;
    }

    name = () => {
        return `${this.dataset_name} - ${this.annotation_type_name}`;
    };

    formatDeadline = (): string => {
        return this.deadline.toLocaleDateString("it-IT", {
            day: "numeric",
            month: "long",
            year: "numeric",
        });
    };

    progress = () => {
        return this.annotations / this.medias;
    };

    isCompleted = () => {
        return this.annotations === this.medias;
    };
}
