import { format, set } from "date-fns";
import itLocale from "date-fns/locale/it";
import nameChecker from "../ViewModel/NameChecker";
import diagnose_image from "../imgs/icons/diagnose_image.png";
import { useEffect, useState } from "react";
import DeanonymizationCC from "../../common/Model/Communication/DeanonymizationCommunicationController";
import Status from "../../common/Model/Status";
import { useLocation } from "react-router-dom";
import formatType from "../ViewModel/FormatVisitType";
import { CircularProgress } from "@mui/material";

export default function VisitLine(props) {

    const [physician, setPhysician] = useState({});
    const [loadingPhysicianDetails, setLoadingPhysicianDetails] = useState(false);
    const [patient, setPatient] = useState({});
    const [loadingPatientDetails, setLoadingPatientDetails] = useState(false);
    const [status, setStatus] = useState(Status.IDLE);
    const [networkError, setNetworkError] = useState(null);
    const [patientError, setPatientError] = useState(false);

    const location = useLocation();

    const select = () => {
        props.onSelectVisit(patient);
    };

    useEffect(() => {
        getPhysicianDetails();
        getPatientDetails();
    }, []);

    const getPhysicianDetails = async () => {
        setLoadingPhysicianDetails(true);
        try {
            if(props.visit.physician != null) {
                const res = await DeanonymizationCC.getPhysician(props.visit.physician);
                setPhysician(res);
            }
        } catch (err) {
            setNetworkError(err);
            setStatus(Status.ERROR);
        } finally {
            setLoadingPhysicianDetails(false);
        }
    }

    const getPatientDetails = async () => {
        setLoadingPatientDetails(true);
        try {
            const res = await DeanonymizationCC.getPatient(props.visit.patient);
            setPatient(res);
            //if(res.name) props.onAddPatientNameToList({name: res.name + " " + res.surname, pid: res.pid});
        } catch (err) {
            setPatientError(true);
            setNetworkError(err);
            setStatus(Status.ERROR);
        } finally {
            setLoadingPatientDetails(false);
        }
    }

    const formatDate = () => {
        const dateString = format(
            new Date(props.visit.date),
            "cccc dd MMMM y",
            {
                locale: itLocale,
            }
        );
        return dateString.charAt(0).toUpperCase() + dateString.slice(1);
    };

    const colorOfData = () => {
        if(patientError) return "gray";
        return "black";
    }

    return (
        <tr
            className="tr-lg"
            style={{
                /*  borderBottom: '0.5px solid lightgray', */ padding: 30,
            }}
            onClick={() => {
                if(!patientError && !loadingPatientDetails) select();
                else {
                    if(!props.visitSelectImpossible) {
                        props.setVisitSelectImpossible(true);
                        setTimeout(() => {
                            props.setVisitSelectImpossible(false);
                        }, 2000);
                    }
                }
            }}
        >
            {/*       <td>{props.visit.id}</td> */}
            {location.pathname === "/" && <td> {!props.visit.has_diagnosis && props.visit.type !== "baseline" && <img src={diagnose_image} width={40} />} </td>}
            <td style={{ color: colorOfData() }}>{formatDate()}</td>
            <td style={{ color: colorOfData() }}>
            {
                loadingPhysicianDetails ? <CircularProgress size={20}/>
                : (nameChecker(physician.name) !== "N/A" ? nameChecker(physician.name) + " " + nameChecker(physician.surname) : "N/A")
            }
            </td>
            <td style={{ color: colorOfData() }}>
            {
                loadingPatientDetails ? <CircularProgress size={20}/>
                : (nameChecker(patient.name) !== "N/A" ? nameChecker(patient.name) + " " + nameChecker(patient.surname) : "N/A")
            }
            </td>
            <td style={{ color: colorOfData() }}>{formatType(props.visit)}</td>
        </tr>
    );
}
