import config from "../../../config";
import { AnnotationToolAccess } from "../User";
import PhysicianTask from "../../../admin/Model/PhysicianTask";
import Dataset, { DatasetData, MediaStats } from "../../../admin/Model/Dataset";
import AnnotationTool, {
  AnnotationToolData,
} from "../../../admin/Model/AnnotationTool";
import AnnotationType from "../../../admin/Model/AnnotationType";
import Task from "../Task";
import AbstractCommunicationController from "./AbstractCommunicationController";
import DeanonymizationCC from "./DeanonymizationCommunicationController";
import TaskStatus, {
  AssignmentType,
  TaskConflictsMatrix,
  TaskData,
} from "../TaskStatus";
import Conflict, { ConflictDetails } from "../Conflict";

class AnnotationCommunicationController extends AbstractCommunicationController {
  private endpoints = {
    GET_TASKS: "/task",
    GET_PHYSICIAN_TASKS: "/task/physician",
    GET_TASK_STATUS: "/task/:id",
    ANNOTATION_TOOL_ACCESS: "/annotationTool/access",
    TOGGLE_USER_TOOL_ACCESS: "/annotationTool/:id/physician",
    GET_DATASET: "/dataset",
    COMPLETE_DATASET: "/dataset/:id/complete",
    GET_ANNOTATION_TOOLS: "/annotationTool",
    GET_ANNOTATION_TYPES: "/annotationType",
    UPDATE_ANNOTATION_TOOL: "/annotationTool/:id",
    GET_PRINT_FUNCTIONS: "/annotationType/printFunctions",
    GET_CONFLICT_FUNCTIONS: "/annotationType/conflictFunctions",
    NEW_ANNOTATION_TYPE: "/annotationType",
    UPDATE_ANNOTATION_TYPE: "/annotationType/:id",
    DELETE_DATASET: "/dataset/:id",
    DELETE_ANNOTATION_TOOL: "/annotationTool/:id",
    DELETE_ANNOTATION_TYPE: "/annotationType/:id",
    NEW_DATASET: "/dataset",
    NEW_ANNOTATION_TOOL: "/annotationTool",
    NEW_TASK: "/task",
    DELETE_TASK: "/task/:id",
    ASSIGN_TASK: "/task/:id/assign",
    GET_CONFLICT_MATRIX: "/task/:id/conflicts/compare",
    GET_MEDIA_STATS: "/media/stats",
    GET_TASK_CONFLICTS: "/task/:id/conflicts",
    GET_TASK_CONFLICT_DETAILS: "/task/:id/conflict",
    ACCEPT_CONFLICT: "/task/conflict/accept",
  };

  protected getHeaders(): { [key: string]: string } {
    let session = "";
    const sessionObjsString = localStorage.getItem(config.LOCAL_STORAGE_SESSION_KEY);
    let sessionObjs = sessionObjsString ? JSON.parse(sessionObjsString) : [];
    if(sessionObjs.length > 0) {
      session = sessionObjs.find((obj: any) => obj.active).sid;
    }

    return {
      [DeanonymizationCC.SESSION_HEADER_NAME]: session ?? "",
    };
  }

  getPhysicianTasks = async (
    id: number,
    cnt?: number,
    offset?: number,
  ): Promise<PhysicianTask[]> => {
    const tasks = await this.get(this.endpoints.GET_PHYSICIAN_TASKS, {
      physician: id,
      cnt,
      offset,
    });

    return tasks.map((task: any) => new PhysicianTask(task));
  };

  getTasks = async (
    filter: string = "",
    cnt: number = 10,
    offset: number = 0,
  ): Promise<Task[]> => {
    const tasks = await this.get(this.endpoints.GET_TASKS, {
      filter,
      cnt,
      offset,
    });

    return tasks.map((task: any) => new Task(task));
  };

  getTaskStatus = async (id: number): Promise<TaskStatus> => {
    const task = await this.get(
      this.formatEndpoint(this.endpoints.GET_TASK_STATUS, id),
    );
    return new TaskStatus(task);
  };

  getUserAnnotationTool = async (
    physician: number,
  ): Promise<AnnotationToolAccess[]> => {
    const res = await this.get(this.endpoints.ANNOTATION_TOOL_ACCESS, {
      physician,
    });

    return res.map((access: any) => {
      return {
        id: access.annotation_tool,
        name: access.annotation_tool_name,
        endpoint: access.endpoint ?? "",
        access: access.access,
      };
    });
  };

  toggleUserAnnotationToolAccess = async (
    physician: number,
    annotationTool: number,
    access: boolean,
    endpoint?: string,
  ): Promise<string> => {
    const res = await this.patch(
      this.formatEndpoint(
        this.endpoints.TOGGLE_USER_TOOL_ACCESS,
        annotationTool,
      ),
      {
        physician,
        grant: access,
        endpoint,
      },
    );
    return res.instructions ?? "";
  };

  getDatasets = async (): Promise<any[]> => {
    const datasets = await this.get(this.endpoints.GET_DATASET);

    return datasets.map((dataset: Dataset) => new Dataset(dataset));
  };

  setDatasetCompleted = async (dataset: number) => {
    await this.patch(
      this.formatEndpoint(this.endpoints.COMPLETE_DATASET, dataset),
    );
  };

  getAnnotationTools = async (): Promise<AnnotationTool[]> => {
    const annotationTools = await this.get(this.endpoints.GET_ANNOTATION_TOOLS);

    return annotationTools.map((annotationTool: AnnotationTool) => {
      return new AnnotationTool(annotationTool);
    });
  };

  getAnnotationTypes = async (
    annotationTool: number,
  ): Promise<AnnotationType[]> => {
    const annotationTypes = await this.get(
      this.endpoints.GET_ANNOTATION_TYPES,
      { annotation_tool: annotationTool },
    );

    return annotationTypes.map((annotationType: AnnotationType) => {
      return new AnnotationType(annotationType);
    });
  };

  updateAnnotationTool = async (
    annotationTool: number,
    data: AnnotationToolData,
  ) => {
    await this.patch(
      this.formatEndpoint(
        this.endpoints.UPDATE_ANNOTATION_TOOL,
        annotationTool,
      ),
      data,
    );
  };

  getPrintFunctions = async (): Promise<string[]> => {
    return this.get(this.endpoints.GET_PRINT_FUNCTIONS);
  };

  getConflictFunctions = async (): Promise<string[]> => {
    return this.get(this.endpoints.GET_CONFLICT_FUNCTIONS);
  };

  newAnnotationType = async (
    annotation_tool: number,
    data: {
      name: string;
      description: string;
      annotation_instructions: string;
      annotation_interface: string;
      print_function: string;
      conflict_functions: string[];
    },
  ): Promise<AnnotationType> => {
    const res = await this.post(this.endpoints.NEW_ANNOTATION_TYPE, {
      annotation_tool,
      name: data.name,
      description: data.description,
      annotation_instructions: data.annotation_instructions,
      annotation_interface: data.annotation_interface,
      print_function: data.print_function,
      conflict_functions: data.conflict_functions,
    });

    return new AnnotationType(res);
  };

  updateAnnotationType = async (
    annotationType: number,
    data: {
      name: string;
      annotation_instructions: string;
      annotation_interface: string;
      print_function: string;
      conflict_functions: string[];
    },
  ) => {
    await this.patch(
      this.formatEndpoint(
        this.endpoints.UPDATE_ANNOTATION_TYPE,
        annotationType,
      ),
      data,
    );
  };

  deleteDataset = async (dataset: number) => {
    await this.delete(
      this.formatEndpoint(this.endpoints.DELETE_DATASET, dataset),
    );
  };

  deleteAnnotationTool = async (annotationTool: number) => {
    await this.delete(
      this.formatEndpoint(
        this.endpoints.DELETE_ANNOTATION_TOOL,
        annotationTool,
      ),
    );
  };

  deleteAnnotationType = async (annotationType: number) => {
    await this.delete(
      this.formatEndpoint(
        this.endpoints.DELETE_ANNOTATION_TYPE,
        annotationType,
      ),
    );
  };

  newDataset = async (data: DatasetData): Promise<Dataset> => {
    const res = await this.post(this.endpoints.NEW_DATASET, data);
    return new Dataset(res);
  };

  newAnnotationTool = async (
    data: AnnotationToolData,
  ): Promise<AnnotationTool> => {
    const res = await this.post(this.endpoints.NEW_ANNOTATION_TOOL, data);
    return new AnnotationTool(res);
  };

  newAnnotationTask = async (data: TaskData): Promise<Task> => {
    const res = await this.post(this.endpoints.NEW_TASK, data);
    return new Task(res);
  };

  deleteAnnotationTask = async (task: number) => {
    await this.delete(this.formatEndpoint(this.endpoints.DELETE_TASK, task));
  };

  updateTaskAssignment = (
    id: number,
    assignments: AssignmentType[],
  ): Promise<
    {
      user: number;
      deadline: string;
      annotations: number;
      task_url: string;
    }[]
  > => {
    return this.patch(this.formatEndpoint(this.endpoints.ASSIGN_TASK, id), {
      assignments,
    });
  };

  getConflictMatrix = async (
    task: number,
    fn: string,
  ): Promise<TaskConflictsMatrix> => {
    const matrix = await this.get(
      this.formatEndpoint(this.endpoints.GET_CONFLICT_MATRIX, task),
      {
        fn,
      },
    );
    matrix.fn = fn;
    return matrix;
  };

  getMediaStats = (): Promise<MediaStats> => {
    return this.get(this.endpoints.GET_MEDIA_STATS);
  };

  getTaskConflicts = async (
    task: number,
    annotator: number,
    conflict_annotator: number,
    conflict_function: string,
    cnt: number = 20,
    offset: number = 0,
  ): Promise<Conflict[]> => {
    const res = await this.get(
      this.formatEndpoint(this.endpoints.GET_TASK_CONFLICTS, task),
      {
        annotator,
        conflict_annotator,
        fn: conflict_function,
        cnt,
        offset,
      },
    );

    return res.map(
      (conf: any) =>
        new Conflict(
          annotator,
          conflict_annotator,
          conflict_function,
          task,
          conf,
        ),
    );
  };

  getConflictDetails(
    task: number,
    annotator: number,
    conflict_annotator: number,
    media: number,
    conflict_function: string,
  ): Promise<ConflictDetails> {
    return this.get(
      this.formatEndpoint(this.endpoints.GET_TASK_CONFLICT_DETAILS, task),
      {
        annotator,
        conflict_annotator,
        media,
        fn: conflict_function,
      },
    );
  }

  acceptConflict = (
    annotation: number,
    accepted_annotation: number,
  ): Promise<void> => {
    return this.patch(this.endpoints.ACCEPT_CONFLICT, {
      annotation,
      accepted_annotation,
    });
  };
}

const instance = new AnnotationCommunicationController(config.MAIN_API_URL);

export default instance;
