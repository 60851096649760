import config from "../../../config";
import User, { UserData, UserType } from "../User";
import AbstractCommunicationController from "./AbstractCommunicationController";

class DeanonymizationCommunicationController extends AbstractCommunicationController {
  SESSION_HEADER_NAME = "ApolloSession";

  private endpoints = {
    GET_PHYSICIANS: "/user/physician",
    GET_PHYSICIAN: "/user/physician/:id",
    LOGIN: "/user/login",
    LOGOUT: "/user/logout",
    RESET_USER_PASSWORD: "/user/resetPassword",
    NEW_PHYSICIAN: "/user/physician",
    GET_PATIENTS: "/patient",
    GET_PATIENT_DETAILS: "/patient/:id",
    NEW_PATIENT: "/patient",
    UPDATE_PATIENT: "/patient/:id",
    MERGE_PATIENTS: "/patient/merge",
  };

  protected getHeaders(endpoint: string): { [key: string]: string } {
    let session = "";
    if(endpoint !== this.endpoints.LOGIN || (endpoint === this.endpoints.LOGIN && localStorage.getItem("reload") === "true")) {
      const sessionObjsString = localStorage.getItem(config.LOCAL_STORAGE_SESSION_KEY);
      let sessionObjs = sessionObjsString ? JSON.parse(sessionObjsString) : [];
      if(sessionObjs.length > 0) {
        session = sessionObjs.find((obj: any) => obj.active).sid;
      }
    }
    
    return {
      [this.SESSION_HEADER_NAME]: session ?? "",
    };
  }

  getPhysicians = async (): Promise<User[]> => {
    const users = await this.get(this.endpoints.GET_PHYSICIANS);
    return users.map((user: User) => new User(user));
  };

  getPhysician = async (id: number): Promise<User> => {
    const user = await this.get(
      this.formatEndpoint(this.endpoints.GET_PHYSICIAN, id),
    );

    return new User(user);
  };

  login = async (email?: string, password?: string): Promise<User> => {
    if(email === "") localStorage.setItem("reload", "true");
    else localStorage.setItem("reload", "false");
    const res = await this.post(this.endpoints.LOGIN, { email, password });
    console.log(res);
    const sessionObjsString = localStorage.getItem(config.LOCAL_STORAGE_SESSION_KEY);
    let sessionObjs = sessionObjsString ? JSON.parse(sessionObjsString) : [];
    if (sessionObjs.length > 0) {
      sessionObjs = sessionObjs.map((obj: any) => {
        if (obj.active) {
          obj.active = false;
        }
        if (obj.sid === res.session.sid) {
          obj.active = true;
        }
        return obj;
      });
      if (!sessionObjs.some((obj: any) => obj.sid === res.session.sid)) {
        sessionObjs.push({
          sid: res.session.sid,
          expire: res.session.expire,
          active: true,
          id: res.id,
          type: res.type,
        });
      }
    } else {
      sessionObjs.push({
        sid: res.session.sid,
        expire: res.session.expire,
        active: true,
        id: res.id,
        type: res.type,
      });
    }
    localStorage.setItem(config.LOCAL_STORAGE_SESSION_KEY, JSON.stringify(sessionObjs));
    return new User(res);
  };

  logout = async () => {
    await this.post(this.endpoints.LOGOUT);
    const sessionObjsString = localStorage.getItem(config.LOCAL_STORAGE_SESSION_KEY);
    let sessionObjs = sessionObjsString ? JSON.parse(sessionObjsString) : [];
    sessionObjs = sessionObjs.filter((obj: any) => !obj.active);
    localStorage.setItem(config.LOCAL_STORAGE_SESSION_KEY, JSON.stringify(sessionObjs));
  };

  generateNewPassword = async (email: string): Promise<string> => {
    const res = await this.post(this.endpoints.RESET_USER_PASSWORD, {
      email,
    });
    return res.password;
  };

  resetPassword = async (
    email: string,
    old_password: string,
    new_password: string,
  ): Promise<string> => {
    const res = await this.post(this.endpoints.RESET_USER_PASSWORD, {
      email,
      old_password,
      new_password,
    });
    return res.password;
  };

  newPhysician = async (data: UserData): Promise<User> => {
    const res = await this.post(this.endpoints.NEW_PHYSICIAN, {
      ...data,
      type: UserType.PHYSICIAN,
    });
    return new User(res);
  };

  getPatients = async (search: string, cnt: number, offset: number = 0) => {
    const res = await this.get(this.endpoints.GET_PATIENTS, {
      search,
      cnt,
      offset,
    });
    return res;
  };

  getPatient = async (pid: string) => {
    const res = await this.get(
      this.formatEndpoint(this.endpoints.GET_PATIENT_DETAILS, pid),
    );
    return res;
  };

  newPatient = async (data: {
    name: string;
    surname: string;
    birthdate: string | Date;
    gender?: "M" | "F";
    height?: number;
    weight?: number;
    cf?: string;
    hemophilia?: "A" | "B" | "C";
    hemophilia_gravity?: 0 | 1 | 2;
  }) => {
    const res = await this.post(this.endpoints.NEW_PATIENT, data);
    return res;
  };

  updatePatient = async (
    pid: string,
    data: {
      name?: string;
      surname?: string;
      birthdate?: string | Date;
      gender?: "M" | "F";
      height?: number;
      weight?: number;
      cf?: string;
      hemophilia?: "A" | "B" | "C";
      hemophilia_gravity?: 0 | 1 | 2;
    },
  ) => {
    await this.patch(
      this.formatEndpoint(this.endpoints.UPDATE_PATIENT, pid),
      data,
    );
  };

  mergePatients = async (old_patient: string, new_patient: string) => {
    await this.patch(this.endpoints.MERGE_PATIENTS, {
      old_patient,
      new_patient,
    });
  };
}

const instance = new DeanonymizationCommunicationController(
  config.DEANONYMIZATION_API_URL,
);

export default instance;