import { useContext, useState, useEffect } from "react";
import unimi from "../imgs/logo_unimi.png";
import ospedale from "../imgs/ospedale-loghi.jpeg";
import UserContext from "../Model/LoggedUserContext";
import DeanonymizationCC from "../Model/Communication/DeanonymizationCommunicationController";
import Status from "../Model/Status";
import config from "../../config";
import { CircularProgress } from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";

export default function Login() {
    const [status, setStatus] = useState(Status.IDLE);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [networkError, setNetworkError] = useState(null);

    const [, setUser] = useContext(UserContext);

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setStatus(Status.LOADING);

        try {
            const user = await DeanonymizationCC.login(email, password);
            setUser(user);
            setStatus(Status.IDLE);
        } catch (err) {
            setNetworkError(err);
            setStatus(Status.ERROR);
        }
    };

    const getUserFromLS = async () => {
        //localStorage.setItem(config.LOCAL_STORAGE_SESSION_KEY, "ciao");
        const sessionObjsString = localStorage.getItem(config.LOCAL_STORAGE_SESSION_KEY);
        try {
            let sessionObjs = sessionObjsString ? JSON.parse(sessionObjsString) : [];
        
            const activeSessionId = sessionObjs.find(sessionId => sessionId.active);
            if(activeSessionId != undefined) {
                const expireDate = Date.parse(activeSessionId ? activeSessionId.expire : null); //"2024-03-01T23:00:00.000Z");//
                if (expireDate > Date.now()) {
                    handleSubmit();
                } else {
                    sessionObjs = sessionObjs.filter((obj) => obj.sid !== activeSessionId.sid);
                    localStorage.setItem(config.LOCAL_STORAGE_SESSION_KEY, JSON.stringify(sessionObjs));
                    setNetworkError({response: {data: {message: "session expired"}}});
                    setStatus(Status.ERROR);
                }
            }
        }
        catch(err) {
            console.log(err);
            if (err instanceof SyntaxError) {
                setNetworkError({response: {data: {message: "obsolete storage format"}}});
                setStatus(Status.ERROR);
                localStorage.clear();
            }
        }
    };

    useEffect(() => {
        getUserFromLS();
    }, []);

    return (
        <div style={style.box}>
            <div style={{ paddingTop: "5%", flex: 1 }}>
                <h2>Autenticarsi</h2>
            </div>

            <div style={style.images}>
                <div>
                    <img
                        src={unimi}
                        alt="Logo dell'università degli studi di Milano"
                        style={{ maxWidth: "90%", height: "auto" }}
                    />
                </div>
                <div>
                    <img
                        src={ospedale}
                        alt="Logo dell'ospedale policlinico di Milano"
                        style={{ maxWidth: "90%", height: "auto" }}
                    />
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flex: 1,
                }}
            >
                {status === Status.ERROR && (
                    <Alert
                        severity="error"
                        variant="filled"
                        style={{ width: "100%" }}
                    >
                        <AlertTitle>
                            {networkError.response
                                ? networkError.response.data.message.includes(
                                        "user"
                                    )
                                    ? "Utente inesistente"
                                    : networkError.response.data.message.includes(
                                            "password"
                                        )
                                    ? "Password non corretta"
                                    : networkError.response.data.message.includes(
                                            "obsolete"
                                        )
                                    ? "Aggiornamento software rilevato, effettuare nuovamente il login"
                                    : networkError.response.data.message.includes(
                                          "session"
                                        )
                                    ? "Sessione scaduta, effettuare nuovamente il login"
                                    : "Errore di rete"
                                : "Errore di rete"}
                        </AlertTitle>
                        {console.log(networkError)}
                    </Alert>
                )}
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    flex: 2,
                }}
            >
                {status === Status.LOADING ? (
                    <CircularProgress />
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="input-group mb-3">
                            <input
                                defaultValue={email}
                                type="text"
                                placeholder="email"
                                onChange={(e) => {
                                    setStatus(Status.IDLE);
                                    setEmail(e.target.value);
                                }}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <input
                                defaultValue={password}
                                type="password"
                                placeholder="password"
                                onChange={(e) => {
                                    setStatus(Status.IDLE);
                                    setPassword(e.target.value);
                                }}
                            />
                        </div>
                        <div className="input-group mb-3">
                            <button
                                className="btn btn-primary"
                                type="submit"
                                disabled={status === Status.LOADING}
                                style={{ margin: "auto" }}
                            >
                                Login
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    );
}

const style = {
    box: {
        width: "40%",
        padding: "3vh",
        height: "65vh",
        borderRadius: "15px",
        background: "white",
        margin: "auto",
        marginTop: "10vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignText: "center",
        alignItems: "center",
    },
    images: {
        padding: "1.5%",
        flex: 2,
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        justifyContent: "space-around",
        alignItems: "center",
    },
};
