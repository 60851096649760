export default function formatType(visit) {
    let textToShow = visit.remote ? "Remota" : "In presenza";
        switch (visit.type) {
            case "checkup":
                textToShow += " - Check-up";
                break;
            case "urgent":
                textToShow += " - Urgente";
                break;
            case "baseline":
                textToShow += " - Baseline";
                break;
            case "first_followup":
                textToShow += " - Follow-up 1°";
                break;
            case "second_followup":
                textToShow += " - Follow-up 2°";
                break;
            default:
                textToShow += " - /";
        }
    return textToShow;
}