import { Modal, Button, AlertHeading } from "react-bootstrap";
import { Alert, AlertTitle, Typography } from "@mui/material";
import Patient from "../../Model/Patient";
import { useEffect, useState, useRef, useCallback, useContext } from "react";
import { useLocation } from "react-router-dom";
import "react-day-picker/dist/style.css";
import format from "date-fns/format";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/it";
import DeanonymizationCC from "../../../common/Model/Communication/DeanonymizationCommunicationController";
import VisitCC from "../../../common/Model/Communication/VisitCommunicationController";
import { CircularProgress } from "@mui/material";
import { SkeletonsList } from "../SkeletonsList";
import PatientLine from "../PatientLine";
import { Link, useNavigate } from "react-router-dom";
import search from "../../imgs/icons/search.png";
import add from "../../imgs/icons/add-user.png";
import { PatientContext } from "../../Model/Contexts/PatientContext";
import { NewVisitContext } from "../../Model/Contexts/NewVisitContext";
import { RefreshButton } from "../RefreshButton";

const PATIENTS_AT_TIME = 20;

export default function ChangePatientModal(props) {
    
    const [showListOfPatients, setShowListOfPatients] = useState(false);
    const [patientListToShow, setPatientListToShow] = useState([]);
    const [loadingFirstPatients, setLoadingFirstPatients] = useState(false);
    const [loadingOtherPatients, setLoadingOtherPatients] = useState(false);
    const [networkError, setNetworkError] = useState(null);
    const [offset, setOffset] = useState(0);
    const [endReached, setEndReached] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [loadingChangePatient, setLoadingChangePatient] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [disable, setDisable] = useState(false);
    const throttledScroll = useRef(null);

    const navigate = useNavigate();

    const { selectedPatient, setSelectedPatient } = useContext(PatientContext);
    const { newVisit, setNewVisit } = useContext(NewVisitContext);

    useEffect(() => {
        if (props.show) {
          getPatients("", 0);
        }
    }, [props.show]);

    useEffect(() => {
        let searchTimeout;
        if (searchInput.length >= 3) {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(() => {
            clearAll();
            getPatients(searchInput, 0);
            }, 500);
        } else if (searchInput.length === 0) {
            clearAll();
            getPatients("", 0);
        }
        return () => clearTimeout(searchTimeout);
    }, [searchInput]);
    
    const clearAll = () => {
        setOffset(0);
        setPatientListToShow([]);
        setNetworkError(null);
        setEndReached(false);
    };

    const getPatients = async (searchTerm, offsetParam) => {
        try {
          let patients = await DeanonymizationCC.getPatients(
            searchTerm,
            PATIENTS_AT_TIME,
            offsetParam,
          );
          setOffset(offsetParam);
          if (patients.length === 0 || patients.length < PATIENTS_AT_TIME)
            setEndReached(true);
          if (patients.length > 0) {
            setPatientListToShow((prevState) => [...prevState, ...patients]);
          }
        } catch (err) {
          setNetworkError(err || "Errore inatteso");
        } finally {
          setLoadingFirstPatients(false);
          setLoadingOtherPatients(false);
        }
    };

    const handleSelectCorrectPatient = async (patient) => {
        setLoadingChangePatient(true);
        try {
            let patch = await VisitCC.patchVisitPatient(
              newVisit.visitId,
              patient.pid,
            );
            setSelectedPatient(patient);
            props.getVisits();
        } catch (err) {
            setNetworkError(err || "Errore inatteso");
            setShowAlert(true);
        } finally {
            setLoadingChangePatient(false);
        }
    };

    const handleScroll = useCallback((e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (!endReached && (scrollTop + clientHeight) / scrollHeight >= 0.95) {
            if (!throttledScroll.current) {
                throttledScroll.current = setTimeout(() => {
                    setLoadingOtherPatients(true);
                    getPatients("", offset + PATIENTS_AT_TIME);
                    throttledScroll.current = null;
                }, 750);
            }
        }
    });

    return (
        <Modal show={props.show} animation={true} size="lg" centered>
            <Modal.Header style={{ backgroundColor: "#0288d1" }}>
                {showListOfPatients ? (
                    <Typography style={{ fontSize: 24, color: "white" }}>
                        Selezionare il paziente corretto dalla lista e continuare
                    </Typography>
                ) : (
                    <Typography style={{ fontSize: 24, color: "white" }}>
                        Nuovo paziente rilevato: continuare con {selectedPatient ? selectedPatient.name : ""} {selectedPatient ? selectedPatient.surname : ""}?
                    </Typography>
                )}
            </Modal.Header>
            {showListOfPatients && <Modal.Body style={{ background: "whitesmoke" }}>
                <div
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "left",
                        alignItems: "center",
                        gap: "1vw",
                    }}
                >
                    <img src={search} width={30} height={30} />
                    <input
                        style={{ width: "65%", fontSize: 24 }}
                        type="text"
                        name="name"
                        placeholder="cerca per nome o cognome..."
                        onChange={(e) => {
                        setOffset(0);
                        setSearchInput(e.target.value);
                        }}
                        value={searchInput}
                        disabled={disable}
                    />
                </div>
                <div
                    onScroll={handleScroll}
                    style={{
                        width: "100%",
                        height: "55vh",
                        overflow: "auto",
                        textAlign: loadingFirstPatients || networkError !== null ? "center" : "left",
                        borderRadius: "15px",
                        border: "0.5px solid #56AEC9",
                        boxShadow: "1px 2px 6px #56AEC9",
                        marginTop: "1%",
                    }}
                    >
                    {loadingFirstPatients && <SkeletonsList />}
                    {networkError !== null && (
                        <div style={{ marginTop: "1%" }}>
                        Errore nell'ottenere lista pazienti
                        <RefreshButton
                            onClick={() => {
                            getPatients();
                            }}
                        />
                        </div>
                    )}
                    {patientListToShow.length > 0 && selectedPatient !== null && !loadingFirstPatients &&
                        networkError === null &&
                        patientListToShow.length > 0 && (
                        <table className="table table-primary table-striped table-hover">
                            <thead
                            style={{
                                position: "sticky",
                                top: 0,
                                height: "6vh",
                                textAlign: "center",
                            }}
                            >
                            <tr>
                                <th style={{ background: "white" }}>Cognome</th>
                                <th style={{ background: "white" }}>Nome</th>
                                <th style={{ background: "white" }}>Data di nascita</th>
                                <th style={{ background: "white" }}>CF</th>
                                <th style={{ background: "white" }}></th>
                            </tr>
                            </thead>
                            <tbody style={{ textAlign: "center" }}>
                            {patientListToShow
                                .filter(
                                    (item, index, self) => index === self.findIndex((t) => t.pid === item.pid)
                                )
                                //.filter((patient) => patient.pid !== selectedPatient.pid)
                                .map((patient, index) => (
                                    <PatientLine
                                        key={index}
                                        patient={patient}
                                        isSelected={patient === selectedPatient}
                                        onSelectPatient={() => {
                                            handleSelectCorrectPatient(patient);
                                        }}
                                    />
                                ))
                            }
                            </tbody>
                        </table>
                        )
                    }
                    <div
                        style={{
                        display: "flex",
                        justifyContent: "center",
                        }}
                    >
                        {loadingOtherPatients && <CircularProgress />}
                    </div>
                    {endReached && (
                        <tfoot
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: 14,
                        }}
                        >
                        <p>
                            <em>Non sono presenti altri pazienti</em>
                        </p>
                        </tfoot>
                    )}
                </div>
            </Modal.Body>}

            <Modal.Footer style={{ background: "whitesmoke", display: "flex", justifyContent: "center", gap: "5%", }}>
                {showAlert && (
                    <Alert
                        style={{ width: "100%" }}
                        severity="warning"
                        onClose={() => {
                            props.setShow(false);
                            setShowAlert(false);
                        }}
                        variant="filled"
                    >
                        <AlertTitle>Errore nel cambiamento del paziente</AlertTitle>
                    </Alert>
                )}
                <Button
                    variant="secondary"
                    onClick={(e) => {
                        setTimeout(() => {
                            setShowListOfPatients(false);
                            setSelectedPatient(null);
                            setNewVisit(null);
                        }, 200);
                        props.setShow(false);
                    }}
                    style={{ fontSize: 24 }}
                >
                    Annulla
                </Button>
                <Button
                    variant="primary"
                    onClick={(e) => {
                        props.setShow(false);
                        navigate("/newVisit", { replace: true });
                    }}
                    disabled={loadingChangePatient}
                    style={{ fontSize: 24 }}
                >
                    {loadingChangePatient ? (
                            "..."
                        ) : (
                            "Continua"
                        )
                    }
                </Button>
                {!showListOfPatients && <Button
                    variant="primary"
                    onClick={(e) => {
                        setShowListOfPatients(true);
                    }}
                    style={{ fontSize: 24 }}
                >
                    Associa altro paziente
                </Button>}
            </Modal.Footer>
        </Modal>
    );
}